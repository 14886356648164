<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span class="option_txt">거래처 현황</span>
        <div>
          <span class="option_txt">거래처 구분</span>
          <div>
            <my-local-selectric
              :id="'search_type_selectric'"
              :watch="searchType"
              :options="
                company_options_all.concat({ value: 9, label: '삭제기록' })
              "
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
        <div>
          <span class="option_txt">거래처명</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="거래처명 검색"
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredCompanys.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>거래처명</th>
              <th>최근거래일</th>
              <th>현잔고</th>
              <th v-show="managementMode">
                {{ searchType != 9 ? '삭제' : '복구' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(company, index) in filteredCompanys"
              :key="company.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>
                {{ company.company_type_id == 1 ? '매출처' : '구매처' }}
              </td>
              <td class="text_left">{{ company.name }}</td>
              <td>{{ company.recent_date }}</td>
              <td class="text_right">
                &#8361; {{ $makeComma(company.curr_balance) }}
              </td>
              <td v-show="managementMode && searchType != 9">
                <button @click="ShowModal(index)" class="tbl_delete_btn">
                  delete
                </button>
              </td>
              <td
                v-show="managementMode && searchType == 9"
                class="tbl_restore"
              >
                <button @click="restoreCompany(company)" class="btn_tbl">
                  {{ `◀` }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>
          {{
            selectedIndex == -1
              ? '세부정보'
              : searchType == 1 ||
                (managementData.company_type_id == 1 && selectedIndex != -1)
              ? '매출처'
              : '구매처'
          }}
        </h5>
        <button
          class="btn_sub1"
          v-show="
            searchType == 2 ||
              (managementData.company_type_id == 2 &&
                selectedIndex != -1 &&
                !managementMode)
          "
          v-if="isPermissionOn"
          @click="goPurchase(managementData.id)"
        >
          발주 신청
        </button>
        <button
          class="btn_sub1"
          v-show="selectedIndex != -1"
          v-if="isPermissionOn"
          @click="copyData()"
        >
          복사 후 신규등록
        </button>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: checkValidate.includes('거래처 구분') && baseInfo,
              }"
              >거래처 구분</label
            >
            <my-selectric
              :id="'management_company_type_selectric'"
              :watch="managementData.company_type_id"
              :options="companyOption"
              :commit="'setManagementCompanyTypeId'"
              :state="!managementMode || selectedIndex == -1 ? 'disabled' : ''"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: checkValidate.includes('거래처명') && baseInfo,
              }"
              >거래처명</label
            >
            <input
              v-model="managementData.name"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
              required
            />
          </div>

          <div class="input_text">
            <label>대표명</label>
            <input
              v-model="managementData.representative"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
            />
          </div>
          <div class="input_text">
            <label>담당자</label>
            <input
              v-model="managementData.manager"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
            />
          </div>
          <div class="input_text">
            <label>업태</label>
            <input
              v-model="managementData.business_type"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
            />
          </div>
          <div class="input_text">
            <label>종목</label>
            <input
              v-model="managementData.business_item"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
            />
          </div>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: checkValidate.includes('사업자번호') && baseInfo,
              }"
              >사업자번호</label
            >
            <input
              v-model="managementData.saup_no"
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="번호를 입력하세요"
              maxlength="12"
            />
          </div>
          <div>
            <div class="input_text">
              <label>주소</label>
              <div class="address_search">
                <input
                  disabled
                  type="text"
                  placeholder="우편번호 입력"
                  v-model="managementData.post_number"
                />
                <button
                  :disabled="!managementMode || selectedIndex == -1"
                  type="button"
                  class="btn_sub2"
                  @click="postSearch()"
                >
                  찾기
                </button>
              </div>
              <input
                disabled
                type="text"
                placeholder="주소 입력"
                v-model="managementData.address"
              />
              <input
                :disabled="!managementMode || selectedIndex == -1"
                type="text"
                placeholder="상세 주소 입력"
                v-model="managementData.detail_address"
              />
            </div>
          </div>
          <div class="input_text">
            <label>대표 이메일</label>
            <input
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
              v-model="managementData.email"
            />
          </div>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: checkValidate.includes('대표 번호') && baseInfo,
              }"
              >대표 번호</label
            >
            <input
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
              v-model="managementData.phone_number"
            />
          </div>
          <div class="input_text">
            <label>팩스 번호</label>
            <input
              :disabled="!managementMode || selectedIndex == -1"
              type="text"
              placeholder="내용을 입력하세요"
              v-model="managementData.fax_number"
            />
          </div>
          <div class="input_text">
            <label>거래개시일</label>
            <input
              :readonly="!managementMode || selectedIndex == -1"
              type="date"
              placeholder="YYYY-MM-DD"
              v-model="managementData.business_date"
            />
          </div>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: checkValidate.includes('부가세구분') && baseInfo,
              }"
              >부가세구분</label
            >
            <my-selectric
              :id="'management_vat_selectric'"
              :watch="managementData.vat"
              :options="vatOptions"
              :commit="'setManagementCompanyVatId'"
              :state="!managementMode || selectedIndex == -1 ? 'disabled' : ''"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>비고</label>
            <textarea
              :disabled="!managementMode || selectedIndex == -1"
              v-model="managementData.detail"
            ></textarea>
          </div>
          <div class="btn_wrap" v-show="managementMode" v-if="isPermissionOn">
            <button
              class="btn_sub2"
              type="button"
              @click="submitForm()"
              :disabled="isValidModify"
            >
              <!-- :disabled="isValidModify || !checkValid" //저장비활성화하는 함수 -->
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      z-index="9999"
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteCompany($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric.vue';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import PriceMixin from '@/mixins/price';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { clone } from '@/utils/func';
// import { yyyymmdd } from '@/utils/func';
import { mapGetters, mapMutations } from 'vuex';
import routes from '@/routes/routes';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, PriceMixin],
  components: {
    MySelectric,
    TwoButtonModal,
    MyLocalSelectric,
  },
  data() {
    return {
      companyOption: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '매출처',
          value: 1,
        },
        {
          label: '구매처',
          value: 2,
        },
      ],
      vatOptions: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '부가세별도',
          value: 1,
        },
        {
          label: '부가세포함',
          value: 2,
        },
        {
          label: '영세율',
          value: 3,
        },
      ],
      baseInfo: false,
      search_value: '',
      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getVisibleCompany',
      del_companys: 'getDeletedCompany',
      company_types: 'getCompanyType',
      date: 'getDateFromCompanyPage',
      company_options_all: 'getCompanyTypeForSelectricAll',
      managementMode: 'getManagementModeFromCompanyPage',
      selectedIndex: 'getSelectedIndexFromCompany',
      searchType: 'getSearchTypeFromCompanyPage',
      managementData: 'getManagementDataFromCompany',
      newData: 'getNewDataFromCompany',
    }),
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        let modifyData = this.lodash.clonedeep(this.managementData);
        modifyData.name = modifyData.name.trim();
        modifyData.saup_no = modifyData.saup_no.trim();
        modifyData.phone_number = modifyData.phone_number.trim();
        modifyData = JSON.stringify(modifyData);
        const originData = JSON.stringify(
          this.filteredCompanys[this.selectedIndex],
        );
        if (modifyData == originData) {
          return true;
        } else return false;
      } else return true;
    },
    filteredCompanys() {
      if (this.companys.length == 0 && this.del_companys.length == 0) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        const companySelect = this.lodash.clonedeep(
          this.searchType != 9 ? this.companys : this.del_companys,
        );
        return companySelect
          .filter(
            x =>
              x.company_type_id == this.searchType ||
              this.searchType == null ||
              this.searchType == 9,
          )
          .filter(
            x =>
              x.name.includes(this.search_value) ||
              Hangul.d(x.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.search_value),
          )
          .sort((a, b) => {
            return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
          });
      }
    },
    checkValidate() {
      let chk = [];

      if (this.managementData.company_type_id == null) {
        chk.push('거래처 구분');
      }
      if (
        this.managementData.name == undefined ||
        this.managementData.name.trim() == ''
      ) {
        chk.push('거래처명');
      }

      if (
        this.managementData.saup_no == null ||
        this.managementData.saup_no.trim() == ''
      ) {
        chk.push('사업자번호');
      }

      if (
        this.managementData.phone_number == null ||
        this.managementData.phone_number.trim() == ''
      ) {
        chk.push('대표 번호');
      }

      if (this.managementData.vat == null) {
        chk.push('부가세구분');
      }

      return chk;
    },
    chkSameCompany() {
      const findSameCompany = this.lodash
        .clonedeep(this.companys)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.name == this.managementData.name &&
            x.company_type_id == this.managementData.company_type_id,
        );

      if (findSameCompany != undefined) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapMutations({
      setAddress: 'setManagementCompanyAddress',
      setPostNum: 'setManagementCompanyPostNumber',
    }),
    async restoreCompany(company) {
      this.showSpinner();
      await this.$store
        .dispatch('RESTORE_COMPANY', company.id)
        .then(async () => {
          await this.FETCH_COMPANY();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('복구실패', '거래처 복구 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.SelectRow(-1);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    copyData() {
      this.$store.commit('setNewDataToCompany', clone(this.managementData));
      this.$store.commit('setOpenTabIndexToCompanyPage', 1);
    },
    SelectRow(index) {
      this.$store.commit('setSelectedIndexToCompany', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToCompany', {
          company_type_id: null, // 회사 유형
          name: '', //회사명
          representative: '', //대표자
          business_type: '', //업태
          business_item: '', //업종
          representative_item: '', //대표 품목
          address: '', //주소
          detail_address: '', //상세 주소
          account_number: '', //계좌번호,
          post_number: '', //우편번호
          email: '', //이메일
          phone_number: '', //대표 번호
          fax_number: '', //팩스번호
          business_date: '', //거래 개시일
          saup_no: '', //사업자번호
          vat: null, //부가세 구분
          detail: '', //비고
        });
      } else {
        this.$store.commit(
          'setManagementDataToCompany',
          this.lodash.clonedeep(this.filteredCompanys[index]),
        );
      }
    },
    postSearch() {
      if (this.$screen.width < 1280) {
        this.openOneButtonModal(
          '',
          'Tablet모드에서 지원하지 않는 기능입니다.<br/>PC에서 작업해주세요.',
        );
      } else {
        const setAddress = this.setAddress;
        const setPostNum = this.setPostNum;
        // eslint-disable-next-line no-undef
        new daum.Postcode({
          oncomplete(data) {
            setAddress(data.address);
            setPostNum(data.zonecode);
            $('#postCode').val(data.zonecode);
            $('#defaultAddress').val(data.address);
          },
        }).open();
      }
    },
    async FETCH_COMPANY_TYPE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COMPANY_TYPE')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal('로드 중 오류', '거래처 유형 로드 중 오류');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_COMPANY() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COMPANY', this.date)
        .then(response => {
          console.log(response);
        })
        .catch(response => {
          console.log(response);
          this.openOneButtonModal('로드 중 에러', '거래처 로드 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    submitForm() {
      if (this.checkValidate.length < 1 && !this.chkSameCompany) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.managementData);
        payload.name = payload.name.trim();
        payload.saup_no = payload.saup_no.trim();
        payload.phone_number = payload.phone_number.trim();
        this.$store
          .dispatch('UPDATE_COMPANY', payload)
          .then(() => {
            this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
            this.baseInfo = false;
            this.FETCH_COMPANY();
            this.SelectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정에 실패하였습니다.');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.checkValidate.length > 0) {
        this.openOneButtonModal(
          '내용 입력',
          `${this.checkValidate.join()}
          은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
        return;
      } else if (this.chkSameCompany) {
        this.openOneButtonModal(
          '동일 거래처 존재',
          `이미 동일한 거래처가 등록이 되어있습니다.`,
        );
        this.baseInfo = true;
        return;
      }
    },
    async deleteCompany(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;
      this.showSpinner();
      //박스 삭제
      this.$store
        .dispatch('DELETE_COMPANY', this.managementData.id)
        .then(() => {
          this.FETCH_COMPANY();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    goPurchase(companyId) {
      console.log('확인', companyId);
      const goingPage = routes[0].children.find(x => x.path == '/plan/order');
      if (goingPage != undefined) {
        goingPage.meta.tab = 1;
        goingPage.meta.select = this.lodash.clonedeep(companyId);
      }
      this.$router.push('/plan/order');
    },
    changeSelectric(type) {
      this.SelectRow(-1);
      this.$store.commit('setSearchTypeToCompanyPage', type);
    },
  },
  async created() {
    // if (this.start_date == undefined) {
    //   const today = new Date();
    //   this.$store.commit('setEndDateToCompanyPage', yyyymmdd(today));
    //   const lastYear = new Date();
    //   lastYear.setYear(lastYear.getYear() - 20);
    //   this.$store.commit('setStartDateToCompanyPage', yyyymmdd(lastYear));
    // }

    if (this.company_types.length == 0) {
      await this.FETCH_COMPANY_TYPE();
    }
    if (this.companys.length == 0) {
      await this.FETCH_COMPANY();
    }
  },
};
</script>

<style scoped></style>
