<template>
  <div class="article">
    <div class="aside_management_mode client_regist_mode">
      <div class="head">
        <div class="input_text">
          <label
            class="require"
            :class="{
              base_info: checkValidate.includes('거래처 구분') && baseInfo,
            }"
            >거래처 구분
          </label>
          <my-selectric
            :id="'company_type_selectric'"
            :watch="newData.company_type_id"
            :options="companyOption"
            :commit="'setNewCompanyTypeId'"
          ></my-selectric>
        </div>
      </div>

      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('거래처명') && baseInfo,
                }"
                >거래처명</label
              >
              <input
                type="text"
                placeholder="내용을 입력하세요"
                required
                v-model="newData.name"
              />
            </div>
            <div class="input_text">
              <label>대표명</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.representative"
              />
            </div>
            <div class="input_text">
              <label>담당자</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.manager"
              />
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('사업자번호') && baseInfo,
                }"
                >사업자번호</label
              >
              <input
                type="text"
                v-model="newData.saup_no"
                placeholder="번호를 입력하세요"
                maxlength="12"
                required
              />
            </div>
            <div>
              <div class="input_text">
                <label>주소</label>
                <div class="address_search">
                  <input
                    id="postCode"
                    type="text"
                    placeholder="우편번호 입력"
                    readonly
                    v-model="newData.post_number"
                  />
                  <button type="button" class="btn_sub2" @click="postSearch()">
                    찾기
                  </button>
                </div>
                <input
                  id="defaultAddress"
                  type="text"
                  placeholder="주소 입력"
                  v-model="newData.address"
                  readonly
                />
                <input
                  type="text"
                  placeholder="상세 주소 입력"
                  v-model="newData.detail_address"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>대표 이메일</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.email"
              />
            </div>
            <div class="input_text">
              <label>업태</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.business_type"
              />
            </div>
            <div class="input_text">
              <label>종목</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.business_item"
              />
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('대표 번호') && baseInfo,
                }"
                >대표 번호</label
              >
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.phone_number"
                required
              />
            </div>
            <div class="input_text">
              <label>팩스 번호</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                v-model="newData.fax_number"
              />
            </div>
            <div class="input_text">
              <label>거래개시일</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="newData.business_date"
              />
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('부가세구분') && baseInfo,
                }"
                >부가세구분</label
              >
              <my-selectric
                :id="'vat_selectric'"
                :watch="newData.vat"
                :options="vatOptions"
                :commit="'setNewCompanyVatId'"
              ></my-selectric>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>비고</label>
              <textarea v-model="newData.detail"></textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button class="btn_sub2" type="button" @click="submitForm">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/price';
import MySelectric from '@/layouts/components/MySelectric.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ModalMixin, SpinnerMixin, PriceMixin],
  components: {
    MySelectric,
  },
  data() {
    return {
      companyOption: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '매출처',
          value: 1,
        },
        {
          label: '구매처',
          value: 2,
        },
      ],
      vatOptions: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '부가세별도',
          value: 1,
        },
        {
          label: '부가세포함',
          value: 2,
        },
        {
          label: '영세율',
          value: 3,
        },
      ],
      baseInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getVisibleCompany',
      company_types: 'getCompanyType',
      newData: 'getNewDataFromCompany',
    }),
    checkValidate() {
      let chk = [];
      if (this.newData.company_type_id == null) {
        chk.push('거래처 구분');
      }

      if (this.newData.name == undefined || this.newData.name.trim() == '') {
        chk.push('거래처명');
      }

      if (this.newData.saup_no == null || this.newData.saup_no.trim() == '') {
        chk.push('사업자번호');
      }

      if (
        this.newData.phone_number == null ||
        this.newData.phone_number.trim() == ''
      ) {
        chk.push('대표 번호');
      }

      if (this.newData.vat == null) {
        chk.push('부가세구분');
      }

      return chk;
    },
    chkSameCompany() {
      const findSameCompany = this.lodash
        .clonedeep(this.companys)
        .find(
          x =>
            x.name == this.newData.name &&
            x.company_type_id == this.newData.company_type_id,
        );

      if (findSameCompany != undefined) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapMutations({
      setAddress: 'setNewCompanyAddress',
      setPostNum: 'setNewCompanyPostNumber',
    }),
    async FETCH_COMPANY_TYPE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COMPANY_TYPE')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal('로드 중 오류', '거래처 유형 로드 중 오류');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_COMPANY() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COMPANY', this.date)
        .then(response => {
          console.log(response);
        })
        .catch(response => {
          console.log(response);
          this.openOneButtonModal('로드 중 에러', '거래처 로드 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    RefreshSelectric(arg1) {
      //company_type
      console.log(arg1);
      this.newData.company_type_id = Number(arg1);
      $('#comptype-selectric').selectric('refresh');
    },
    RefreshSelectric2(arg1) {
      //vat
      console.log(arg1);
      this.newData.vat = Number(arg1);
      $('#comptype-selectric').selectric('refresh');
    },
    postSearch() {
      if (this.$screen.width < 1280) {
        this.openOneButtonModal(
          '',
          'Tablet모드에서 지원하지 않는 기능입니다.<br/>PC에서 작업해주세요.',
        );
      } else {
        const setAddress = this.setAddress;
        const setPostNum = this.setPostNum;
        // eslint-disable-next-line no-undef
        new daum.Postcode({
          oncomplete(data) {
            setAddress(data.address);
            setPostNum(data.zonecode);
            $('#postCode').val(data.zonecode);
            $('#defaultAddress').val(data.address);
          },
        }).open();
      }
    },
    submitForm() {
      if (this.checkValidate.length < 1 && !this.chkSameCompany) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.newData);
        payload.name = payload.name.trim();
        payload.saup_no = payload.saup_no.trim();
        payload.phone_number = payload.phone_number.trim();
        this.$store
          .dispatch('INSERT_COMPANY', payload)
          .then(() => {
            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.$store.dispatch('FETCH_COMPANY');
            this.baseInfo = false;
            this.$store.commit('setNewDataToCompany', {
              company_type_id: null, // 회사 유형
              name: '', //회사명
              representative: '', //대표자
              business_type: '', //업태
              business_item: '', //업종
              representative_item: '', //대표 품목
              address: '', //주소
              detail_address: '', //상세 주소
              account_number: '', //계좌번호,
              post_number: '', //우편번호
              email: '', //이메일
              phone_number: '', //대표 번호
              fax_number: '', //팩스번호
              business_date: new Date(),
              saup_no: '', //사업자번호
              vat: null, //부가세 구분
              detail: '', //비고
              manager: '',
            });
            this.$store.commit('setSelectedIndexToCompany', -1);
            this.$store.commit('setManagementDataToCompany', {
              company_type_id: null, // 회사 유형
              name: '', //회사명
              representative: '', //대표자
              business_type: '', //업태
              business_item: '', //업종
              representative_item: '', //대표 품목
              address: '', //주소
              detail_address: '', //상세 주소
              account_number: '', //계좌번호,
              post_number: '', //우편번호
              email: '', //이메일
              phone_number: '', //대표 번호
              fax_number: '', //팩스번호
              business_date: '', //거래 개시일
              saup_no: '', //사업자번호
              vat: 1, //부가세 구분
              detail: '', //비고
              manager: '',
            });
          })
          .catch(() => {
            this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.checkValidate.length > 0) {
        this.openOneButtonModal(
          '내용 입력',
          `${this.checkValidate.join()}
          은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
        return;
      } else if (this.chkSameCompany) {
        this.openOneButtonModal(
          '동일 거래처 존재',
          `이미 동일한 거래처가 등록이 되어있습니다.`,
        );
        this.baseInfo = true;
        return;
      }
    },
  },
  async created() {
    if (this.company_types.length == 0) {
      this.FETCH_COMPANY_TYPE();
    }
    await this.FETCH_COMPANY();
  },
};
</script>

<style scoped></style>
