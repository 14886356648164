<template>
  <div id="contents" class="client_management">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToCompanyPage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 0"
            href="#"
            >관리</a
          >
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">조회&수정</a>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">등록</a>
          </li>
        </ul>
      </div>
      <company-modification-form
        v-if="tabIndex == 0"
      ></company-modification-form>
      <company-new-form v-if="tabIndex == 1"></company-new-form>
    </div>
  </div>
</template>

<script>
import CompanyModificationForm from '@/views/forms/Base/CompanyModificationForm.vue';
import CompanyNewForm from '@/views/forms/Base/CompanyNewForm.vue';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    CompanyModificationForm,
    CompanyNewForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCompanyPage',
      tabIndex: 'getOpenTabIndexFromCompanyPage',
      start_date: 'getStartDateFromCompanyPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToCompanyPage',
      SetOpenTabIndex: 'setOpenTabIndexToCompanyPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitCompanyPage');
      this.$route.meta.menu_init = false;
    }
    // if (this.start_date == undefined) {
    //   this.$store.commit('resetDateToCompanyPage');
    // }
  },
};
</script>

<style scoped></style>
